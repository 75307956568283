// BigDaddyMarketplaceContext.js

import { useState, createContext, useContext } from 'react';
import BigDaddyMarketplaceTransactions from '../Flow/BigDaddyMarketplaceTransactions';
import BigDaddyMarketplaceScripts from '../Flow/BigDaddyMarketplaceScripts';
import {useBDNHubContext} from "./bdnHubContext";
import { useToast } from './BigDaddyToastProvider';

const BigDaddyMarketplaceContext = createContext();

export function useBigDaddyMarketplaceContext() {
  return useContext(BigDaddyMarketplaceContext);
}

export function BigDaddyMarketplaceProvider({ children }) {
  const [nftList, setNFTList] = useState([]);
  const [bigDaddyMarketplaceTemplates, setBigDaddyMarketplaceTemplates] = useState({});

  const bigDaddyMarketplaceScripts = new BigDaddyMarketplaceScripts();
  const bigDaddyMarketplaceTransactions = new BigDaddyMarketplaceTransactions();
  const pushToast = useToast();

  const { user,
    setIsBigDaddyLoading,
    setIsBigDaddyErrorModalOpen,
    setBigDaddyErrorMessage } = useBDNHubContext();

  const verifyURLPrefix = async (urlPrefix) => {
    setIsBigDaddyLoading(true);
    let isValid = false;
    try {
      isValid = bigDaddyMarketplaceScripts.verifyURLPrefix(urlPrefix);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
    return isValid
  };

  const getPersonalBigDaddyMarketplaceNFTList = async () => {
    setIsBigDaddyLoading(true);
    let fetchedNFTList = [];
    try {
      fetchedNFTList = await bigDaddyMarketplaceScripts.getPersonnalBigDaddyMarketplaceNFTList(user.addr);
      setNFTList(fetchedNFTList);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
    return fetchedNFTList;
  };

  const getBigDaddyMarketplaceTemplates = async () => {
    setIsBigDaddyLoading(true);
    try {
      const NFTList = await bigDaddyMarketplaceScripts.getBigDaddyMarketplaceTemplates();
      setBigDaddyMarketplaceTemplates(NFTList);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };


  const handleBuyBigDaddyMarketplaceNFT = async (templateId) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyMarketplaceTransactions.buyBigDaddyMarketplaceNFT(templateId);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      await getPersonalBigDaddyMarketplaceNFTList();
      setIsBigDaddyLoading(false);
      pushToast();
    }
  };

  const handleDeployBigDaddyMarketplaceNFT = async (nftId, websiteTitle, websiteDescription, siteId, urlPrefix, token) => {
    try {
      setIsBigDaddyLoading(true);

      await bigDaddyMarketplaceTransactions.deployBigDaddyMarketplaceNFT(nftId, websiteTitle, websiteDescription, siteId, urlPrefix, token);

      const updatedNFTList = await getPersonalBigDaddyMarketplaceNFTList();

      const nft = updatedNFTList.find(nft => nft.nftId === nftId);
      if (nft) {
      await deploy(siteId, nft.urlPrefix, nft.data.projectId);
      }
      else {
        throw new Error('Failed deploy ');
      }
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
      pushToast();
    }
  };

const deploy = async (siteId, urlPrefix, projectId) => {
//TODO: move base_url in .env
const response = await fetch('https://us-central1-bigdaddynft.cloudfunctions.net/forkAndDeploy', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    siteId: siteId,
    urlPrefix: urlPrefix,
    projectId: projectId
  }),
});
if (!response.ok) {
  throw new Error('Failed deploy ');
}

};


  return (
    <BigDaddyMarketplaceContext.Provider value={{ getPersonalBigDaddyMarketplaceNFTList,
      nftList,
                                      bigDaddyMarketplaceTemplates,
                                      handleDeployBigDaddyMarketplaceNFT,
                                      handleBuyBigDaddyMarketplaceNFT,
                                      getBigDaddyMarketplaceTemplates,
                                      verifyURLPrefix }}>
      {children}
    </BigDaddyMarketplaceContext.Provider>
  );
}
