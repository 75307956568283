import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
} from '@coreui/react';

function BigDaddyStartPage() {
    return (
        <CCard className="m-5" style={{ background: "rgba(255, 255, 255, 0.6)" }}>
            <CCardHeader className="text-center" style={{ background: "rgba(255, 255, 255, 0.4)" }}>
                <p style={{
                    background: 'linear-gradient(to right, #0099cc, #008ca8, #00b580, #00ff80)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontSize: "42px"
                }}>
                    Start fundraising!
                </p>
            </CCardHeader>

            <CCardBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <iframe width="75%" height="542" src="https://www.youtube.com/embed/32cbSA4VpFw"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
            </CCardBody>
        </CCard>
    );
}

export default BigDaddyStartPage;

