// BigDaddyContext.js

import { useState, createContext, useContext } from 'react';
import BigDaddyDeveloperTransactions from '../Flow/BigDaddyDeveloperTransactions';
import BigDaddyDeveloperScripts from '../Flow/BigDaddyDeveloperScripts';
import {useBDNHubContext} from "./bdnHubContext";
import { useToast } from './BigDaddyToastProvider';

const BigDaddyDeveloperContext = createContext();

export function useBigDaddyDeveloperContext() {
  return useContext(BigDaddyDeveloperContext);
}

export function BigDaddyDeveloperProvider({ children }) {
  const [nftTemplate, setNFTTemplate] = useState(null);

  const { user,
    setIsBigDaddyLoading,
    setIsBigDaddyErrorModalOpen,
    setBigDaddyErrorMessage } = useBDNHubContext();

  const bigDaddyScripts = new BigDaddyDeveloperScripts();
  const bigDaddyTransactions = new BigDaddyDeveloperTransactions();
  const pushToast = useToast();


  const getBigDaddyDeveloperTemplate = async () => {
    setIsBigDaddyLoading(true);
    try {
      const template = await bigDaddyScripts.getTemplatebyAuthor(user.addr);
      setNFTTemplate(template);
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  const handleCreateNewBigDaddyNFTTemplate = async (name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.CreateNewBigDaddyNFTTemplate(name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) 
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      await getBigDaddyDeveloperTemplate();
      setIsBigDaddyLoading(false);
      pushToast();
    }
  };

  const handleUpdateBigDaddyNFTTemplate = async (id, name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.UpdateBigDaddyNFTTemplate(id, name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) 
    } catch (error) {
      setBigDaddyErrorMessage(error.message);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      await getBigDaddyDeveloperTemplate();
      setIsBigDaddyLoading(false);
      pushToast();
    }
  };

  return (
    <BigDaddyDeveloperContext.Provider value={{ nftTemplate,
                                            getBigDaddyDeveloperTemplate,
                                            handleCreateNewBigDaddyNFTTemplate, 
                                            handleUpdateBigDaddyNFTTemplate}}>
      {children}
    </BigDaddyDeveloperContext.Provider>
  );
}
