import './App.css';
import './BigDaddy.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import MarketplacePublicPage from './Components/bdnMkt/MarketplacePublicPage';
import BigDaddyNavBar from './Components/bdnhub/BigDaddyNavBar';
import {BrowserRouter , Routes, Route } from 'react-router-dom';
import React from 'react';
import './BigDaddy-flow-config'
import BigDaddyDeveloperComponent from "./Components/bdnDev/BigDaddyDeveloperComponent";
import BigDaddyMinterComponent from "./Components/bdnCreator/BigDaddyMinterComponent";
import {BigDaddyMarketplaceProvider} from "./Providers/BigDaddyMarketplaceContext";
import BigDaddyMarketplaceComponent from "./Components/bdnMkt/BigDaddyMarketplaceComponent";
import {BigDaddyMinterProvider} from "./Providers/BigDaddyMinterContext";
import {BigDaddyDeveloperProvider} from "./Providers/BigDaddyDeveloperContext";
import BigDaddyErrorAndLoading from "./Components/bdnhub/BigDaddyErrorAndLoading";
import {BdnHubProvider} from "./Providers/bdnHubContext";
import BigDaddyHowItWorksPage from "./Components/bdnhub/BigDaddyHowItWorksPage";
import BigDaddyWhyUsPage from "./Components/bdnhub/BigDaddyWhyUsPage";
import BigDaddyDevExpPage from "./Components/bdnhub/BigDaddyDevExpPage";
import BigDaddyStartPage from "./Components/bdnhub/BigDaddyStartPage";
import BigDaddyContactPage from "./Components/bdnhub/BigDaddyContactPage";
import BigDaddyToastProvider from "./Providers/BigDaddyToastProvider";
import BigDaddyHomePage from "./Components/bdnhub/BigDaddyHomePage";

function App() {
  return (
    <BrowserRouter basename="/">
        <BigDaddyToastProvider>
        <BdnHubProvider>
     <BigDaddyMarketplaceProvider >
         <BigDaddyDeveloperProvider >
             <BigDaddyMinterProvider >
                <div className="BigDaddyContainer">
                    <BigDaddyNavBar />
                    <BigDaddyErrorAndLoading />
                    <Routes>
                        <Route path="/" element={<BigDaddyHomePage />} />
                        <Route path="/mkt" element={<MarketplacePublicPage />} />
                        <Route path="/websites" element={<BigDaddyMarketplaceComponent />} />
                        <Route path="/collections" element={<BigDaddyMinterComponent />} />
                        <Route path="/dev" element={<BigDaddyDeveloperComponent />} />
                        <Route path="/hiw" element={<BigDaddyHowItWorksPage />} />
                        <Route path="/whyus" element={<BigDaddyWhyUsPage />} />
                        <Route path="/devexp" element={<BigDaddyDevExpPage />} />
                        <Route path="/start" element={<BigDaddyStartPage />} />
                        <Route path="/contact" element={<BigDaddyContactPage />} />
                    </Routes>
                </div>
             </BigDaddyMinterProvider>
         </BigDaddyDeveloperProvider>
      </BigDaddyMarketplaceProvider>
        </BdnHubProvider>
        </BigDaddyToastProvider>
  </BrowserRouter>
  );
}

export default App;
