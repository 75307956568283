import React from 'react';
import {
    CContainer,
    CHeader,
    CCardBody,
    CCard,
    CRow,
    CCol, CCardHeader, CCardFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cibSkyliner, cilCart, cilGem, cilShieldAlt } from '@coreui/icons';

function BigDaddyHowItWorks() {
    return (
        <CCard className="m-5" style={{ background: "rgba(255, 255, 255, 0.6)" }}>
            <CCardHeader className="text-center" style={{ background: "rgba(255, 255, 255, 0.4)" }}>
                <p style={{
                    background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #00ff80)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontSize: "42px"
                }}>
                    Discover bdnHub
                </p>
                <p>Enter the new era of NFTs with bdnHub, where your digital potential is unlocked.</p>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol md="3" className="text-center my-5">
                        <CIcon icon={cibSkyliner} size="4xl" style={{ color: '#0099cc' }} />
                        <h3 style={{ paddingTop: "10px", color: '#0099cc' }}>Seamless Creation</h3>
                        <p style={{ paddingTop: "10px" }}>Dreamt of crafting your own NFT Collection? With bdnHub, it's not just possible, it's simple! Just navigate to "My Collections" and watch your dream become a reality.</p>
                    </CCol>

                    <CCol md="3" className="text-center my-5">
                        <CIcon icon={cilCart} size="4xl" style={{ color: '#008ca8' }} />
                        <h3 style={{ paddingTop: "10px", color: '#008ca8' }}>Dive into the Marketplace</h3>
                        <p style={{ paddingTop: "10px" }}>Explore our Marketplace, a treasure trove where you can discover the perfect website NFT tailored to your vision. Your digital empire awaits!</p>
                    </CCol>

                    <CCol md="3" className="text-center my-5">
                        <CIcon icon={cilGem} size="4xl" style={{ color: '#00b580' }} />
                        <h3 style={{ paddingTop: "10px", color: '#00b580' }}>Empower with Website NFTs</h3>
                        <p style={{ paddingTop: "10px" }}>Got your hands on a website NFT? It’s more than just a token. Deploy a website, initiate fundraisers, and offer your supporters an unparalleled experience on your private platform.</p>
                    </CCol>

                    <CCol md="3" className="text-center my-5">
                        <CIcon icon={cilShieldAlt} size="4xl" style={{ color: '#00ff80' }} />
                        <h3 style={{ paddingTop: "10px", color: '#00ff80' }}>Trust in Ownership Verification</h3>
                        <p style={{ paddingTop: "10px" }}>Our state-of-the-art bdnHub plugin is on guard to verify NFT ownership, ensuring that only the rightful knights can enter your digital castle.</p>
                    </CCol>
                </CRow>
            </CCardBody>
            <CCardFooter className="text-center" style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                <p style={{ background: 'linear-gradient(to right, #0099cc, #008ca8, #00b580, #00ff80)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    paddingTop: "10px" }}>To sum it up, bdnHub isn’t just another platform! It’s your gateway to monetizing website content with the power of NFTs. Ready to create, sell, or immerse in the NFT world? We’ve paved the way for you.</p>
            </CCardFooter>
        </CCard>
    );
}

export default BigDaddyHowItWorks;
