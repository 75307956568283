import React, { useState, useEffect } from 'react';
import {CFormInput, CFormLabel, CRow, CCol, CFormCheck, CCardBody, CCard, CCardHeader, CImage} from '@coreui/react';
import { useBigDaddyMinterContext } from '../../Providers/BigDaddyMinterContext';

function BigDaddyMinterPage() {
  const [collectionName, setCollectionName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [siteId, setSiteId] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [nftImageURL, setNftImageURL] = useState("");
  const [price, setPrice] = useState(0.00);
  const [minted, setMinted] = useState(0);
  const [royalties, setRoyalties] = useState(0);
  const [selectedToken, setSelectedToken] = useState('FLOW');
  const [bigDaddyCollectionList, setBigDaddyCollectionList] = useState([])
  const [selectedCollection, setSelectedCollection] =useState(null)

  const [collectionOption, setCollectionOption] = useState("Free");


  const { nftTemplateList,
    handleCreateBigDaddyNFTCollection} = useBigDaddyMinterContext();

  useEffect(() => {
    if (nftTemplateList) {
      setBigDaddyCollectionList(nftTemplateList);
      setSelectedCollection(null);
    }
    // eslint-disable-next-line
  }, [nftTemplateList]);

  const handleCreateBigDaddyCollection = () => {
    let finalPrice = price;
    if(Number.isInteger(Number(price))) {
      finalPrice = parseFloat(price).toFixed(1);
    }

    handleCreateBigDaddyNFTCollection(collectionName, finalPrice, quantity, royalties, selectedToken, logoURL, nftImageURL);
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <h1 style={{ margin: '40px', color: 'white'}}>My Private NFT Collections</h1>
      </div>
      <CRow xs={{ cols: 1, gutter: 4 }} className='p-3 m-0' >
        <CCol xs={3}>
          <CCard className="h-100 mb-3" style={{background: "rgba(255, 255, 255, 0.6)"}}>
            <CCardHeader style={{ textAlign: 'center', fontWeight: 'bold',  fontSize: '24px'}}>Personal Collections</CCardHeader>
            <CCardBody>
              {bigDaddyCollectionList.map(collection => (
                  <div
                      key={collection.templateID}
                      onClick={() => {
                        setSelectedCollection(collection);
                        setSiteId(collection.templateKey)
                        setCollectionName(collection.name);
                        setPrice(collection.price);
                        setMinted(collection.minted);
                        setQuantity(collection.limit);
                        setRoyalties(collection.royalties*100);
                        setNftImageURL(collection.nftImageURL);
                        setLogoURL(collection.logoURL)
                      }}
                      className={`nft-item ${selectedCollection === collection ? "selected" : ""}`}
                  >
                    {"#" + collection.templateID.toString()}
                  </div>

              ))}
              <div
                  onClick={() => {
                    setSelectedCollection(null);
                  }}
                  className={`nft-item ${selectedCollection === null ? "selected" : ""}`}
              >
                New collection
              </div>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={9}>
          <CCard className="h-100 mb-3" style={{background: "rgba(255, 255, 255, 0.6)"}}>


            <CCardHeader style={{ textAlign: 'center', fontWeight: 'bold',  fontSize: '24px'}}>Collection Details</CCardHeader>
            {selectedCollection !== null &&
                <>
                <CRow className="mb-3" xs={{ gutterX: 5 }}>
                  <CImage fluid src={logoURL} />
                </CRow>
                  <CRow className="mb-3" xs={{ gutterX: 5 }}>
                    <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>{collectionName}</CFormLabel>
                  </CRow>
                <CRow className="mb-3" xs={{ gutterX: 5 }}>
                  <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Site ID:</CFormLabel>
                  <CCol sm={10} className="mx-3">
                    <CFormInput type="text" value={siteId} disabled />
                  </CCol>
                </CRow>
                </>
            }
            <CRow>
              <CCol xs={5}>
                <div className="helpCard p-5">
                  <div className="cardContent">{selectedCollection === null ? ("?") :(<CImage fluid src={nftImageURL} />)}
                  </div>
                </div>
              </CCol>
              <CCol xs={7}>
            <CCardBody>
              {selectedCollection === null &&
                  <>
            <CRow className="mb-3" xs={{ gutterX: 5 }}>
              <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Collection Name:</CFormLabel>
              <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={collectionName} onChange={e => setCollectionName(e.target.value)} disabled={selectedCollection !== null}/>
              </CCol>
            </CRow>
              </>
              }
            <CRow className="mb-3" xs={{ gutterX: 5 }}>
              <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Price :</CFormLabel>
              <CCol sm={10} className="mx-3">
                <CFormInput type="number" step="0.01" value={price} onChange={e => setPrice(e.target.value)} disabled={selectedCollection !== null}/>
              </CCol>
            </CRow>
            <CRow className="mb-3" xs={{ gutterX: 5 }}>
              <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Quantity to sell:</CFormLabel>
              <CCol sm={10} className="mx-3">
                <CFormInput type="number" value={quantity} onChange={e => setQuantity(e.target.value)} disabled={selectedCollection !== null}/>
              </CCol>
            </CRow>
              <CRow className='m-4'>
                <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}> Choose between free deployment or premium collection (10 FLOW): </CFormLabel>
                <CCol sm={10} className="mx-3">
                  <CFormCheck type="radio" name="deployRadio" id="Free" label="Free"
                              value="Free"
                              onChange={(e) => setCollectionOption(e.target.value)}
                              checked={collectionOption === 'Free'}
                  />
                  <CFormCheck type="radio" name="deployRadio" id="Premium" label="Premium"
                              value="Premium"
                              onChange={(e) => setCollectionOption(e.target.value)}
                              checked={collectionOption === 'Premium'}
                  />
                </CCol>
              </CRow>
              {collectionOption === 'Premium' && (
                  <>
            <CRow className="mb-3" xs={{ gutterX: 5 }}>
              <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Royalties on second hand market in %:</CFormLabel>
              <CCol sm={10} className="mx-3">
                <CFormInput type="number" value={royalties} onChange={e => setRoyalties(e.target.value)} disabled={selectedCollection !== null}/>
              </CCol>
            </CRow>
            <CRow className="mb-3" xs={{ gutterX: 5 }}>
              <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Used token for payment:</CFormLabel>
              <CCol sm={10} className="mx-3">
              <CFormCheck type="radio" name="tokenRadio" id="FLOW" label="FLOW" 
                  value="FLOW"
                  onChange={(e) => setSelectedToken(e.target.value)}
                  checked={selectedToken === 'FLOW'}
                  disabled={selectedCollection !== null}
              />
              <CFormCheck type="radio" name="tokenRadio" id="USDC" label="USDC" 
                  value="USDC"
                  onChange={(e) => setSelectedToken(e.target.value)}
                  checked={selectedToken === 'USDC'}
                  disabled={selectedCollection !== null}
              />
              </CCol>
            </CRow>
                    {selectedCollection === null &&
                        <>
                          <CRow className="mb-3" xs={{ gutterX: 5 }}>
                            <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Logo URL:</CFormLabel>
                            <CCol sm={10} className="mx-3">
                              <CFormInput type="number" value={logoURL} onChange={e => setLogoURL(e.target.value)} disabled={selectedCollection !== null}/>
                            </CCol>
                          </CRow>
                          <CRow className="mb-3" xs={{ gutterX: 5 }}>
                            <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>NFT Image URL (ratio 1:1):</CFormLabel>
                            <CCol sm={10} className="mx-3">
                              <CFormInput type="number" value={nftImageURL} onChange={e => setNftImageURL(e.target.value)} disabled={selectedCollection !== null}/>
                            </CCol>
                          </CRow>
                        </>
                    }
                  </>
              )}
            {selectedCollection !== null &&
              <CRow className="mb-3" xs={{ gutterX: 5 }}>
                <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Quantity already sold:</CFormLabel>
                <CCol sm={10} className="mx-3">
                  <CFormInput type="text" value={minted} disabled />
                </CCol>
              </CRow>
            }
            {selectedCollection === null &&
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                <button
                  className="bigdaddy-button"
                  onClick={handleCreateBigDaddyCollection} >
                  Create my NFT Collection
                </button>
              </div>}
            </CCardBody>
            </CCol>
            </CRow>
          </CCard>

        </CCol>
      </CRow></>

  );
}

export default BigDaddyMinterPage;
