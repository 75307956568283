import React, { useState, useRef, createContext, useContext } from 'react';
import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react";

const ToastContext = createContext();

export const useToast = () => {
    return useContext(ToastContext);
};

const BigDaddyToastProvider = ({ children }) => {
    const [toast, addToast] = useState(null);
    const toaster = useRef();

    const pushToast = () => {
        addToast((
            <CToast>
                <CToastHeader closeButton>
                    <div className="fw-bold me-auto">Success 🎉</div>
                    <small>Just now</small>
                </CToastHeader>
                <CToastBody>
                    Great news! Your transaction on the blockchain was successful 🚀!
                </CToastBody>
            </CToast>
        ));

        setTimeout(() => {
            addToast(null);
        }, 5000);
    };

    return (
        <ToastContext.Provider value={pushToast}>
            {children}
            <CToaster ref={toaster} push={toast} placement="top-end" />
        </ToastContext.Provider>
    );
};

export default BigDaddyToastProvider;
