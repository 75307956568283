// BigDaddyContext.js

import { useState, createContext, useContext } from 'react';
import BigDaddyMinterTransactions from '../Flow/BigDaddyMinterTransactions';
import BigDaddyMinterScripts from '../Flow/BigDaddyMinterScripts';
import {useBDNHubContext} from "./bdnHubContext";

import { useToast } from './BigDaddyToastProvider';
const BigDaddyMinterContext = createContext();

export function useBigDaddyMinterContext() {
  return useContext(BigDaddyMinterContext);
}

export function BigDaddyMinterProvider({ children }) {
  const [nftTemplateList, setNftTemplateList] = useState(null);

  const { user,
    setIsBigDaddyLoading,
    setIsBigDaddyErrorModalOpen,
    setBigDaddyErrorMessage } = useBDNHubContext();

  const bigDaddyScripts = new BigDaddyMinterScripts();
  const bigDaddyTransactions = new BigDaddyMinterTransactions();
  const pushToast = useToast();


  const getBigDaddyTemplates = async () => {
    setIsBigDaddyLoading(true);
    try {
      const templates = await bigDaddyScripts.getTemplatesbyAuthor(user.addr);
      setNftTemplateList(templates);
    } catch (error) {
      setBigDaddyErrorMessage(error);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  const handleCreateBigDaddyNFTCollection = async (collectionName, price, quantity, royalties, token) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.MintNewBigDaddyNFT(collectionName, price, quantity, royalties, token);
    } catch (error) {
      setBigDaddyErrorMessage(error);
      setIsBigDaddyErrorModalOpen(true);
    } finally {
      await getBigDaddyTemplates();
      setIsBigDaddyLoading(false);
      pushToast();
    }
  };


  return (
    <BigDaddyMinterContext.Provider value={{nftTemplateList,
                                            handleCreateBigDaddyNFTCollection}}>
      {children}
    </BigDaddyMinterContext.Provider>
  );
}
