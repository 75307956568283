import React, { useState, useEffect } from 'react';
import '@coreui/coreui/dist/css/coreui.min.css';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormInput,
  CFormLabel,
  CCol,
  CRow,
  CFormTextarea,
  CFormCheck,
  CCollapse, CImage
} from '@coreui/react';
import { useBigDaddyMarketplaceContext } from '../../Providers/BigDaddyMarketplaceContext.js';

function BigDaddyMarketplaceNFTBuyerPage() {

  const [selectedNft, setSelectedNft] = useState(null);
  const [bigDaddyMarketplaceNftList, setBigDaddyMarketplaceNftList] = useState([])
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [siteId, setSiteId] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [deploymentOption, setDeploymentOption] = useState("Free");  // New state for deployment option
  const [premiumUrlPrefix, setPremiumUrlPrefix] = useState("");  // New state for premium URL prefix
  const [isPrefixValid, setIsPrefixValid] = useState(true)

  const {  getPersonalBigDaddyMarketplaceNFTList,
            nftList,
            handleDeployBigDaddyMarketplaceNFT,
            verifyUrlPrefix} = useBigDaddyMarketplaceContext();


  useEffect(() => {
      getPersonalBigDaddyMarketplaceNFTList();
      setBigDaddyMarketplaceNftList(nftList);
      setSelectedNft(null);
  }, []);

  const handlePremiumDeploy = () => {

    if (verifyUrlPrefix(premiumUrlPrefix)) {
      setIsPrefixValid(true)
      handleDeployBigDaddyMarketplaceNFT(selectedNft.nftId, title, description, siteId, premiumUrlPrefix) }
    else{
      setIsPrefixValid(false)
    }
    }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <h1 style={{ margin: '40px', color: 'white'}}>My Website NFTs</h1>
      </div>

      <CRow xs={{ cols: 1, gutter: 4 }} className='p-3 m-0'>
        <CCol xs={3}>
          <CCard className="h-100 mb-3" style={{background: "rgba(255, 255, 255, 0.6)"}}>
            <CCardHeader style={{ textAlign: 'center', fontWeight: 'bold',  fontSize: '24px'}}>Personal Website NFTs</CCardHeader>
            <CCardBody>
              {bigDaddyMarketplaceNftList.sort((a, b) => b.nftId - a.nftId).map(nft => (
                <div
                  key={nft.nftId}
                  onClick={() => {
                    setSelectedNft(nft);
                    setTitle(nft.websiteTitle);
                    setDescription(nft.websiteDescription);
                    setWebsiteUrl('https://' + nft.urlPrefix +'.testnet.bdnhub.com');
                  }}
                  className={`nft-item ${selectedNft === nft ? "selected" : ""}`}
                >
                  {"#" + nft.nftId.toString()}
                </div>
              ))}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs={3}>
          <div className="helpCard p-2">
            <div className="cardContent">
              <CImage fluid src="/image-transp-grand.png" />
            </div>
          </div>
        </CCol>
        <CCol xs={6}>
          <CCard className="h-100 mb-3" style={{background: "rgba(255, 255, 255, 0.6)"}}>
            <CCardHeader style={{ textAlign: 'center', fontWeight: 'bold',  fontSize: '24px'}}>Website NFT Details</CCardHeader>
            <CCardBody>
              {(selectedNft !== null) ? (
                <><h1 style={{ textAlign: 'center', color: 'purple'}}>{(selectedNft.isDeployed) ? ("#" + selectedNft.nftId.toString() + " " + title) : ("#" + selectedNft.nftId.toString())}</h1><div>

                  {(!selectedNft.isDeployed) &&
                  <CRow className='m-4'>
                      <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}> Website Title: </CFormLabel>
                    <CCol sm={10} className="mx-3">
                      <CFormInput type="text" value={title} disabled={selectedNft.isDeployed} onChange={e => setTitle(e.target.value)} />
                    </CCol>
                  </CRow>}

                  <CRow className='m-4'>
                      <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}> Website Description: </CFormLabel>
                    <CCol sm={10} className="mx-3">
                      <CFormTextarea value={description} disabled={selectedNft.isDeployed} onChange={e => setDescription(e.target.value)} />
                    </CCol>
                  </CRow>


                  {(selectedNft.isDeployed) ? (
                    <><CRow className="m-4">
                      <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}> Website URL: </CFormLabel>
                      <CCol sm={10} className="mx-3">
                        <a href={websiteUrl} target="_blank" rel="noopener noreferrer">{websiteUrl}</a>
                      </CCol>
                    </CRow></>
                  ) : (
                    <>
                    <CRow className="m-4">
                      <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}> BigDaddy SiteId: </CFormLabel>
                    <CCol sm={10} className="mx-3">
                      <CFormInput type="text" value={siteId}  onChange={e => setSiteId(e.target.value)} />
                    </CCol>
                  </CRow>
                  <CRow className='m-4'>
                      <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}> Choose between free deployment or premium deployment (20 FLOW): </CFormLabel>
                    <CCol sm={10} className="mx-3">
                      <CFormCheck type="radio" name="deployRadio" id="Free" label="Free" 
                          value="Free"
                          onChange={(e) => setDeploymentOption(e.target.value)}
                          checked={deploymentOption === 'Free'}
                      />
                      <CFormCheck type="radio" name="deployRadio" id="Premium" label="Premium" 
                          value="Premium"
                          onChange={(e) => setDeploymentOption(e.target.value)}
                          checked={deploymentOption === 'Premium'}
                      />
                    </CCol>
                  </CRow>
                  {deploymentOption === 'Premium' ? (
              <>
              <CRow className="m-4">
                <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>URL Prefix:</CFormLabel>
                <CCol sm={10} className="mx-3">
                    <CFormInput type="text" value={premiumUrlPrefix} onChange={e => setPremiumUrlPrefix(e.target.value)} />
                    <CCollapse visible={!isPrefixValid}>
                      <CCard className="mt-3">
                        <CCardBody>
                          <CFormLabel>URL prefix is already used. Please choose another. </CFormLabel>
                        </CCardBody>
                      </CCard>
                    </CCollapse>
                  </CCol>
              </CRow>
                <button className="bigdaddy-button" onClick={ handlePremiumDeploy }>Deploy Premium (10 FLOW)</button>
              </>
            ):(
                <button className="bigdaddy-button" onClick={() => { handleDeployBigDaddyMarketplaceNFT(selectedNft.nftId, title, description, siteId, "") }}>Deploy</button>
            )}
                       </>
                  )}
                </div></>
              ) : null}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
}

export default BigDaddyMarketplaceNFTBuyerPage;
