import React, {useEffect} from "react";
import '@coreui/coreui/dist/css/coreui.min.css';
import {
    CNavbar,
    CNavbarBrand,
    CContainer,
    CDropdownToggle,
    CDropdown, CDropdownMenu, CDropdownDivider, CNavLink, CDropdownItem, CNavbarNav, CCol, CRow
} from '@coreui/react';
import {useLocation, useNavigate} from 'react-router-dom';
import * as fcl from "@onflow/fcl";
import {useBDNHubContext} from "../../Providers/bdnHubContext";


function BigDaddyNavBar() {
    const {  isLoggedIn,
        isCollectionEnabled,
        user,
        flowBalance,
        usdcBalance,
        handleActivateBigDaddyCollections,
        validateLoggedIn,
        getBalance,
        hasBigDaddyCollections,
        disconnect} = useBDNHubContext();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        fcl.currentUser.subscribe((currentUser) => {
            if (currentUser.cid) {
                validateLoggedIn(currentUser);
            } else {
                disconnect();
            }
        });

    }, [validateLoggedIn, disconnect]);

    useEffect(() => {
        if (user) {
            getBalance();
            hasBigDaddyCollections();
        }
    }, [user, flowBalance, usdcBalance, isCollectionEnabled]);
    const handleLogOut = () => {
        fcl.unauthenticate();
      };
    const handleLogIn = () => {
        fcl.logIn().then();
    };
    const redirectToMarketplace = () => {
        navigate("/mkt");
    };

    const redirectToNFTPortal = () => {
        navigate("/websites");
    };

    const redirectToCollections = () => {
        navigate("/collections");
    };

    const redirectToHiW = () => {
        navigate("/hiw");
    };

    const redirectToWhyUs = () => {
        navigate("/whyus");
    };

    const redirectToDevExp = () => {
        navigate("/devexp");
    };

    const redirectToStart = () => {
        navigate("/start");
    };

    const redirectToContact = () => {
        navigate("/contact");
    };
    function isActive(path) {
        return location.pathname === path;
    }

    const navLinkStyle={
        '--cui-nav-link-color': '#510e7c',
        '--cui-nav-link-hover-color': '#ab069d',
        '--cui-nav-link-font-size': '20pt',
        cursor: 'pointer',
        paddingLeft: '40px'
    }

    const navAboutStyle={
        '--cui-nav-link-color': '#510e7c',
        '--cui-nav-link-hover-color': '#ab069d',
        '--cui-nav-link-font-size': '20pt'
    }

    const navSubLinkStyle={
        '--cui-nav-link-color': '#510e7c',
        '--cui-nav-link-hover-color': '#ab069d',
        '--cui-nav-link-font-size': '15pt',
        cursor: 'pointer',
        paddingLeft: '5px', paddingTop: '5px'
    }

    return (<div>

        <CNavbar expand="lg" colorScheme="light" className="bg-light d-flex ">
            <CNavbarBrand href="/">
                <img
                    src={"/bdnhub-little.png"}
                    alt="bdnHub"
                    height="50"
                    className="d-inline-block align-center"
                    style={{ paddingLeft: '30px'}}
                />
            </CNavbarBrand>
            <CContainer className="d-flex justify-content-center">
                <CNavbarNav>
                    <CNavLink onClick={redirectToMarketplace} style={{...navLinkStyle, textDecoration: isActive("/mkt") ? "underline" : "none" }}>Marketplace</CNavLink>
                {isLoggedIn && isCollectionEnabled &&
                    <CNavLink onClick={redirectToNFTPortal} style={{...navLinkStyle, textDecoration: isActive("/websites") ? "underline" : "none" }}>My Websites</CNavLink> }
                {isLoggedIn && isCollectionEnabled &&
                    <CNavLink onClick={redirectToCollections} style={{...navLinkStyle, textDecoration: isActive("/collections") ? "underline" : "none" }}>My NFT Collections</CNavLink> }

                <CDropdown style={{ paddingLeft: '30px'}}>
                    <CDropdownToggle color={"white"} style={{ paddingRight: '0px'}}/>
                    <CNavLink style={navAboutStyle}>About</CNavLink>
                    <CDropdownMenu>
                        <CNavLink onClick={redirectToHiW} style={navSubLinkStyle}>How it works?</CNavLink>
                        <CDropdownDivider />
                        <CNavLink onClick={redirectToWhyUs} style={navSubLinkStyle}>Why us?</CNavLink>
                        <CDropdownDivider />
                        <CNavLink onClick={redirectToStart} style={navSubLinkStyle}>Start</CNavLink>
                        <CDropdownDivider />
                        <CNavLink onClick={redirectToDevExp} style={navSubLinkStyle}>Developer</CNavLink>
                        <CDropdownDivider />
                        <CNavLink onClick={redirectToContact} style={navSubLinkStyle}>Contact</CNavLink>
                    </CDropdownMenu>
                </CDropdown>
                    </CNavbarNav>
            </CContainer>
            <div className="d-flex justify-content-end" style={{ paddingRight: '30px'}}>
                {isLoggedIn ? (!isCollectionEnabled ? (
                    <CDropdown >
                        <CDropdownToggle color={"white"} style={{ paddingRight: '0px'}}/>
                        <CNavLink style={navAboutStyle}>My Account</CNavLink>
                        <CDropdownMenu>
                            <button onClick={handleActivateBigDaddyCollections} className="bigdaddy-button">Activate Account</button>
                        </CDropdownMenu>
                    </CDropdown>
                ) : (
                    <CDropdown >
                        <CNavLink style={navAboutStyle}>My Account</CNavLink>
                        <CDropdownToggle color={"white"} style={{ paddingLeft: '0px'}}/>
                        <CDropdownMenu>
                            <p style={{ paddingLeft: '5px', color: '#47013e' }}>Wallet address: {user.addr}</p>
                            <p style={{ paddingLeft: '5px', paddingTop: '5px',color: '#47013e' }}>FLOW Balance: {parseFloat(flowBalance).toFixed(3)} FLOW</p>
                            <p style={{ paddingLeft: '5px', paddingTop: '5px',color: '#47013e' }}>USDC Balance: {parseFloat(usdcBalance).toFixed(3)} USDC</p>
                            <CDropdownDivider />
                            <button onClick={handleLogOut} className="bigdaddy-button">Log Out</button>
                        </CDropdownMenu>
                    </CDropdown>
                )) : (
                    <button className="bigdaddy-button" onClick={handleLogIn}>Log In</button>
                )}

            </div>
        </CNavbar>
        </div>
    );
}

export default BigDaddyNavBar;