import * as fcl from "@onflow/fcl"

// Importez vos transactions Cadence
import { ENABLE_BIGDADDY_COLLECTIONS_TX } from './transactions/EnableBigDaddyCollections'

class BdnHubTransactions {
    async sendTransaction(cadenceCode, args) {
        const response = await fcl.send([
            fcl.transaction(cadenceCode),
            args,
            fcl.proposer(fcl.currentUser().authorization),
            fcl.payer(fcl.currentUser().authorization),
            fcl.limit(9999),
            fcl.authorizations([               // add this
                fcl.currentUser().authorization  // add this
            ]),
        ])

        const transactionId = await fcl.decode(response)

        await fcl.tx(transactionId).onceSealed()

        return transactionId
    }

    // Créez des fonctions distinctes pour chaque transaction
    async enableBigDaddyCollections() {
        let args = fcl.args([])
        return this.sendTransaction(ENABLE_BIGDADDY_COLLECTIONS_TX, args)
    }
}
export default BdnHubTransactions