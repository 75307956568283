import React from 'react';
import {
    CCard,
    CRow,
    CCol,
    CCardBody,
    CTooltip,
    CCardHeader,
    CCardFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCheck, cilX, cilInfo } from '@coreui/icons';

const features = [
    { name: "Decentralized Architecture", icons: [cilCheck, cilCheck, cilX] },
    { name: "No Financial Middlemen", icons: [cilCheck, cilCheck, cilX] },
    { name: "Commission", value: ['0%', '0%', '8-15%'] },
    { name: "Personal Website", icons: [cilCheck, cilCheck, cilX] },
    { name: "Possible ROI for Backers (NFT SecondHand Market)", icons: [cilCheck, cilCheck, cilX] },
    { name: "Crypto Payment", icons: [cilCheck, cilCheck, cilX] },
    { name: "Backers can pay in $", value: ['Soon', 'Soon', 'yes'] },
    { name: "Backers don't need Crypto Wallet", value: ['Soon', 'Soon', 'yes'] },
    { name: "Dedicated Smart Contract", icons: [ cilX, cilCheck, cilX] },
    { name: "Customized Website", icons: [ cilX, cilCheck, cilX] },
    { name: "Support", value: ['No', 'Email', 'Paid'] }
];

function BigDaddyWhyUsPage() {
    return (
        <CCard className="m-5" style={{ background: "rgba(255, 255, 255, 0.6)" }}>
            <CCardHeader className="text-center" style={{ background: "rgba(255, 255, 255, 0.4)" }}>
                <p style={{
                    background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #00ff80)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontSize: "42px"
                }}>
                    Compare bdnHub
                </p>
            </CCardHeader>
            <CCardBody>
                <CRow className="mb-3 text-center font-weight-bold">
                    <CCol><strong>Features</strong></CCol>
                    <CCol><strong>bdnHub Free</strong></CCol>
                    <CCol>
                        <strong>bdnHub Pro</strong>
                        <CTooltip content="For more details, contact us">
                            <CIcon icon={cilInfo} />
                        </CTooltip>
                    </CCol>
                    <CCol><strong>Other Solutions (e.g., Tipeee)</strong></CCol>
                </CRow>

                {features.map((feature, idx) => (
                    <CRow key={idx} className="mb-3 text-center">
                        <CCol>{feature.name}</CCol>
                        <CCol>
                            {feature.icons ?
                                <CIcon icon={feature.icons[0]} style={feature.icons[0] === cilCheck ? { color: 'green' } : { color: 'red' }} />
                                : feature.value[0]}
                        </CCol>
                        <CCol>
                            {feature.icons ?
                                <CIcon icon={feature.icons[1]} style={feature.icons[1] === cilCheck ? { color: 'green' } : { color: 'red' }} />
                                : feature.value[1]}
                        </CCol>
                        <CCol>
                            {feature.icons ?
                                <CIcon icon={feature.icons[2]} style={feature.icons[2] === cilCheck ? { color: 'green' } : { color: 'red' }} />
                                : feature.value[2]}
                        </CCol>
                    </CRow>
                ))}
            </CCardBody>
            <CCardFooter className="text-center" style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                <p style={{ background: 'linear-gradient(to right, #0099cc, #008ca8, #00b580, #00ff80)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontSize:"42px"
                }}>
                    Why choose something else?
                </p>
            </CCardFooter>
        </CCard>
    );
}
export default BigDaddyWhyUsPage;
