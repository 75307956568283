import React, {useEffect, useState} from 'react';
import { useBigDaddyMarketplaceContext } from '../../Providers/BigDaddyMarketplaceContext.js';
import { CContainer, CFormLabel, CCarousel, CCarouselItem, CImage, CCard, CCardImage, CCardBody, CCardText, CButton, CCardTitle, CCardSubtitle, CRow, CCol, CModal, CModalBody, CModalHeader, CModalTitle, CModalFooter } from '@coreui/react';
import {useBDNHubContext} from "../../Providers/bdnHubContext";



const Card = ({ template }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { handleBuyBigDaddyMarketplaceNFT } = useBigDaddyMarketplaceContext();
  const {  isLoggedIn } = useBDNHubContext();

  const handleBuy = () => {
      closeModal();
      handleBuyBigDaddyMarketplaceNFT(template.templateID);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const buttonDetailStyle = {
    '--cui-btn-bg': '#ab069d',
    '--cui-btn-hover-bg': '#510e7c'
  }

  const cardstyle = { 
    width: '18rem',
    background: "rgba(255, 255, 255, 0.6)"
  }

  return (
    <div>
      < CCard style={cardstyle} className="h-100 mb-3 border-light text-center" textColor="black">
        <CCardImage orientation="top" src={template.miniatureURL} className="cardImageStyle"/>
        <CCardBody>
          <CCardTitle>{template.name}</CCardTitle>
          <CCardSubtitle className="mb-2 text-medium-emphasis">{template.price} {template.token}</CCardSubtitle>
          <CCardText>
            {template.description}
          </CCardText>
          <CButton style={buttonDetailStyle} onClick={openModal}>Details</CButton>
        </CCardBody>
      </CCard>

      <CModal size="lg" visible={modalIsOpen} onClose={() => closeModal()}>
        <CModalHeader onClose={() => closeModal()}>
          <CModalTitle>{template.name}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCarousel controls indicators>
            <CCarouselItem>
              <CImage className="d-block w-100" src={template.picture1Url} alt="slide 1" />
            </CCarouselItem>
            <CCarouselItem>
              <CImage className="d-block w-100" src={template.picture2Url} alt="slide 2" />
            </CCarouselItem>
            <CCarouselItem>
              <CImage className="d-block w-100" src={template.picture3Url} alt="slide 3" />
            </CCarouselItem>
          </CCarousel>
          <CContainer>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
            <CFormLabel className="col-sm-2 col-form-label" style={{color: 'purple'}}>Video</CFormLabel>
            <CCol sm={10} className="mx-3">
              <a href={template.videoUrl} target="_blank" rel="noopener noreferrer">{template.videoUrl}</a>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CFormLabel className="col-sm-2 col-form-label" style={{color: 'purple'}}>Creator Website</CFormLabel>
            <CCol sm={10} className="mx-3">
              <a href={template.creatorwebsiteUrl} target="_blank" rel="noopener noreferrer">{template.creatorwebsiteUrl}</a>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CFormLabel className="col-sm-2 col-form-label" style={{color: 'purple'}}>Demo Website</CFormLabel>
            <CCol sm={10} className="mx-3">
              <a href={template.demowebsiteUrl} target="_blank" rel="noopener noreferrer">{template.demowebsiteUrl}</a>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CFormLabel className="col-sm-2 col-form-label" style={{color: 'purple'}}>Description</CFormLabel>
            <CCol sm={10} className="mx-3">
            {template.description}
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CFormLabel className="col-sm-2 col-form-label" style={{color: 'purple'}}>Price</CFormLabel>
            <CCol sm={10} className="mx-3">
            {template.price}  {template.token}
            </CCol>
          </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          {isLoggedIn ? (<button className="bigdaddy-button" onClick={handleBuy}>Buy Website</button>) : (<CFormLabel className="col-sm-3 col-form-label" style={{color: 'purple'}}>Login before to buy</CFormLabel>)}
        </CModalFooter>
      </CModal>


    </div>
  );
};

const MarketplacePublicPage = () => {
  const { bigDaddyMarketplaceTemplates,
    getBigDaddyMarketplaceTemplates } = useBigDaddyMarketplaceContext();

  useEffect(() => {
    getBigDaddyMarketplaceTemplates();
  }, []);

  return (
      <>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <h1 style={{ margin: '40px', color: 'white'}}>Buy a Website NFT</h1>
      </div>
      <CRow xs={{ cols: 1 }} md={{ cols: 3 }} className="g-4 mx-5">
        {Object.values(bigDaddyMarketplaceTemplates).map((template) => (
          <CCol xs>
            <Card key={template.templateID} template={template} />
          </CCol>
        ))}
      </CRow>
      </>
  )
};

export default MarketplacePublicPage;
