export const DEPLOY_NFT_TX = `import BigDaddyMarketplaceContract from 0x17cfbf06741efebb
import FungibleToken from 0x9a0766d93b6608b7
import FlowToken from 0x7e60df042a9c0868

transaction(nftId: UInt64, websiteTitle: String, websiteDescription: String, siteId: String, urlPrefix: String) {
  let deployerReference: &BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.CollectionPublic}
  let sentVault: @FungibleToken.Vault
  let deployer: Address

  prepare(acct: AuthAccount) {
    self.deployerReference = acct.borrow<&BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.CollectionPublic}>(from: BigDaddyMarketplaceContract.NFTMarketplaceCollectionStoragePath) 
        ?? panic("Cannot borrow")

    self.deployer = acct.address

    if (urlPrefix == ""){

      // Get a reference to the signer's stored vault
      let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
          ?? panic("Could not borrow reference to the owner's Vault!")
      self.sentVault <- vaultRef.withdraw(amount: 0.0)
    }
    else
    {
        let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow reference to the owner's Vault!")
        self.sentVault <- vaultRef.withdraw(amount: 20.0)
    }
  }

  execute {
    self.deployerReference.deployBigDaddyMarketplaceNFT(deployer: self.deployer, nftId: nftId, websiteTitle: websiteTitle, websiteDescription: websiteDescription, siteId: siteId, urlPrefix: urlPrefix, vault: <- self.sentVault)
  }
}`;